import {LOGIN, LOGOUT, SESSION_EXPIRED} from "./types";

const initialState = {
  token: null,
  user: null,
  exp: null,
  status: null
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, token: action.token, user: action.name, status: action.status, exp: action.exp }
    case SESSION_EXPIRED:
      return { ...state, token: null, status: 'SESSION_EXPIRED', exp: null}
    case LOGOUT:
      return { ...state, token: null, exp: null }
    default: return state
  }
}
