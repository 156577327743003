import {
  LOAD_COMPANIES, LOAD_POSITIONS, LOAD_CATEGORIES, LOGOUT
} from "./types";

const initialState = {
  companies: [],
  positions: [],
  categories: [],
  status: null
}

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COMPANIES:
      return { ...state, companies: action.companies, status: action.status }
    case LOAD_POSITIONS:
      return { ...state, positions: action.positions, status: action.status }
    case LOAD_CATEGORIES:
      return { ...state, categories: action.categories, status: action.status }
    case LOGOUT:
      return { ...state, companies: null, positions: null, categories: null, status: null}
    default: return state
  }
}
