import {TOGGLE_SIDEBAR} from "./types";

const initialState = {
  sidebarShow: true,
  unfoldable:true
}

export const appViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarShow: action.sidebarShow }
    default:
      return state
  }
}
