import {
  LOGOUT,
  FIND_COHORT_DATA,
  CLEAR_COHORT_CONTEXT, CHANGE_COHORT_VALUE_KEY, SEARCHING_COHORT_DATA
} from "./types";

const initialState = {
  analysisValue: '',
  startDate: null,
  interval: '',
  companyId: '',
  categoryId: '',
  valueKey: "value",
  data: null,
  absentDataFrom: null,
  absentDataTo: null,
  status: null,
  searchCohortInfo: false,
  initialView: true
}

export const cohortReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_COHORT_DATA:
    {
      return { ...state, analysisValue: action.analysisValue, startDate: action.startDate, interval: action.interval,
        companyId: action.companyId, categoryId: action.categoryId, data: action.data, status: action.status,
        searchCohortInfo: false, initialView: false, absentDataFrom: action.absentDataFrom, absentDataTo: action.absentDataTo
      }
    }
    case CLEAR_COHORT_CONTEXT:
      return { ...state, analysisValue: '', startDate: null, interval: '', companyId: '', categoryId: '',
        data: null, status: null, valueKey: "value", initialView: true, absentDataFrom: null, absentDataTo: null
      }
    case LOGOUT:
      return { ...state, analysisValue: '', startDate: null, interval: '', companyId: '', categoryId: '',
        data: null, status: null, valueKey: "value", initialView: true, absentDataFrom: null, absentDataTo: null
      }
    case SEARCHING_COHORT_DATA:
      return {
        ...state, searchCohortInfo: true, initialView: false
      }
    case CHANGE_COHORT_VALUE_KEY:
      return {
        ...state, valueKey: action.valueKey, searchCohortInfo: false, initialView: false
      }
    default: return state
  }
}
