import {combineReducers} from 'redux'
import {appViewReducer} from "./appViewReducer";
import {userReducer} from "./userReducer";
import {staffReducer} from "./staffReducer";
import {staffGroupReducer} from "./staffGroupReducer";
import {cohortReducer} from "./cohortReducer";
import {commonReducer} from "./commonReducer";

export const rootReducer = combineReducers({
  view: appViewReducer,
  user: userReducer,
  staff: staffReducer,
  staffgroup: staffGroupReducer,
  cohort: cohortReducer,
  common: commonReducer
})
