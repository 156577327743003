import {
  CLEAR_STAFF_CONTEXT,
  CLEAR_STAFF_WORK_PHOTO,
  CLEAR_CHART_DATA,
  FIND_CHART_DATA,
  FIND_STAFF,
  FIND_STAFF_EXAMS,
  FIND_STAFF_FEEDBACK,
  FIND_STAFF_SECRET_CLIENT_FEEDBACK,
  FIND_STAFF_WORK,
  FIND_STAFF_WORK_PHOTO,
  FIND_STAFF_BY_NAME,
  LOGOUT, SEARCHING_STAFF_DATA
} from "./types";

const initialState = {
  staffId: "",
  staffName: "",
  startDate: null,
  endDate: null,
  staffInfo: null,
  staffFeedback: [],
  staffExam: null,
  staffSecretClientFeedback: null,
  staffWork: null,
  staffWorkPhoto: null,
  staffWorkChartData: null,
  staffList: null,
  status: null,
  interval: null,
  staffListStatus: null,
  searchStaffInfo: false,
  searchStaffFeedback: false,
  searchStaffExam: false,
  searchStaffSecretClientFeedback: false,
  searchStaffWork: false,
  searchStaffWorkChartData: false
}

export const staffReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_STAFF:
      return {
        ...state, staffId: action.staffId, staffName: action.staffName, startDate: action.startDate,
        endDate: action.endDate, staffInfo: action.staffInfo, status: action.status, interval: null,
        searchStaffInfo: false
      }
    case FIND_STAFF_FEEDBACK:
      return { ...state, staffFeedback: action.staffFeedback, status: action.status, searchStaffFeedback: false }
    case FIND_STAFF_EXAMS:
      return { ...state, staffExam: action.staffExam, status: action.status, searchStaffExam: false }
    case FIND_STAFF_SECRET_CLIENT_FEEDBACK:
      return {
        ...state, staffSecretClientFeedback: action.staffSecretClientFeedback, status: action.status,
        searchStaffSecretClientFeedback: false
      }
    case FIND_STAFF_WORK:
      return { ...state, staffWork: action.staffWork, status: action.status, searchStaffWork: false }
    case CLEAR_STAFF_WORK_PHOTO:
      return { ...state, staffWorkPhoto: null}
    case FIND_STAFF_BY_NAME:
      return { ...state, staffList: action.staffList, staffListStatus: action.status}
    case FIND_STAFF_WORK_PHOTO:
      return { ...state, staffWorkPhoto: action.staffWorkPhoto}
    case FIND_CHART_DATA:
      return {
        ...state, staffWorkChartData: action.staffWorkChartData, interval: action.interval,
        searchStaffWorkChartData: false
      }
    case CLEAR_CHART_DATA:
      return { ...state, staffWorkChartData: null}
    case SEARCHING_STAFF_DATA:
      return { ...state, searchStaffInfo: true, searchStaffFeedback: true, searchStaffExam: true,
        searchStaffSecretClientFeedback: true, searchStaffWork: true, searchStaffWorkChartData: true
      }
    case CLEAR_STAFF_CONTEXT:
      return { ...state, staffId: '', staffName: '', startDate: null, endDate: null, staffInfo: null, status: null,
        staffFeedback:null, staffExam: null, staffSecretClientFeedback: null, staffWork: null,
        staffWorkPhoto: null, staffWorkChartData: null, staffList: null, staffListStatus: null, interval: null,
        searchStaffInfo: false, searchStaffFeedback: false, searchStaffExam: false,
        searchStaffSecretClientFeedback: false, searchStaffWork: false, searchStaffWorkChartData: false
      }
    case LOGOUT:
      return { ...state, staffId: '', staffName: '', startDate: null, endDate: null,
        staffInfo: null, status: null, staffFeedback: null, staffSecretClientFeedback: null,
        staffWork: null, staffWorkPhoto: null, staffWorkChartData: null, staffList: null,
        staffListStatus: null, interval: null,
        searchStaffInfo: false, searchStaffFeedback: false, searchStaffExam: false,
        searchStaffSecretClientFeedback: false, searchStaffWork: false, searchStaffWorkChartData: false
      }
    default: return state
  }
}
