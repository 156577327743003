export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SESSION_EXPIRED = 'SESSION_EXPIRED'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const FIND_STAFF = 'FIND_STAFF'
export const FIND_STAFF_BY_NAME = 'FIND_STAFF_BY_NAME'
export const FIND_STAFF_FEEDBACK = 'FIND_STAFF_FEEDBACK'
export const FIND_STAFF_EXAMS = 'FIND_STAFF_EXAMS'
export const FIND_STAFF_SECRET_CLIENT_FEEDBACK = 'FIND_STAFF_SECRET_CLIENT_FEEDBACK'
export const FIND_STAFF_WORK = 'FIND_STAFF_WORK'
export const FIND_STAFF_WORK_PHOTO = 'FIND_STAFF_WORK_PHOTO'
export const CLEAR_STAFF_WORK_PHOTO = 'CLEAR_STAFF_WORK_PHOTO'
export const CLEAR_STAFF_CONTEXT = 'CLEAR_STAFF_CONTEXT'
export const CLEAR_CHART_DATA = 'CLEAR_CHART_DATA'
export const FIND_CHART_DATA = 'FIND_CHART_DATA'
export const SEARCHING_STAFF_DATA = 'SEARCHING_STAFF_DATA'

export const FIND_STAFF_GROUP = 'FIND_STAFF_GROUP'
export const FIND_STAFF_GROUP_CHART = 'FIND_STAFF_GROUP_CHART'
export const CLEAR_STAFF_GROUP_CHART = 'CLEAR_STAFF_GROUP_CHART'
export const CLEAR_STAFF_GROUP_CONTEXT = 'CLEAR_STAFF_GROUP_CONTEXT'
export const SEARCHING_STAFF_GROUP_DATA = 'SEARCHING_STAFF_GROUP_DATA'

export const LOAD_COMPANIES = 'LOAD_COMPANIES'
export const LOAD_POSITIONS = 'LOAD_POSITIONS'
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'

export const FIND_COHORT_DATA = 'FIND_COHORT_DATA'
export const CLEAR_COHORT_CONTEXT = 'CLEAR_COHORT_CONTEXT'
export const CHANGE_COHORT_VALUE_KEY = 'CHANGE_COHORT_VALUE_KEY'
export const SEARCHING_COHORT_DATA = 'SEARCHING_COHORT_DATA'
