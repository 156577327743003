import {
  CLEAR_STAFF_GROUP_CONTEXT,
  FIND_STAFF_GROUP,
  FIND_STAFF_GROUP_CHART,
  LOAD_COMPANIES,
  LOAD_POSITIONS,
  CLEAR_STAFF_GROUP_CHART,
  LOGOUT, SEARCHING_STAFF_GROUP_DATA
} from "./types";

const initialState = {
  company_filter: '',
  position_filter: '',
  startDate: null,
  endDate: null,
  fired_filter: '',
  companies: [],
  positions: [],
  data: null,
  chart_data: null,
  interval: null,
  status: null,
  searchStaffGroupInfo: false,
  searchStaffGroupWorkChartData: false
}

export const staffGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case FIND_STAFF_GROUP:
      return { ...state, data: action.data, status: action.status, company_filter: action.company_filter,
        position_filter: action.position_filter, startDate: action.startDate, endDate: action.endDate,
        fired_filter: action. fired_filter, searchStaffGroupInfo: false}
    case FIND_STAFF_GROUP_CHART:
      return {
        ...state, chart_data: action.chart_data, status: action.status, interval: action.interval,
        searchStaffGroupWorkChartData: false
      }
    case LOAD_COMPANIES:
      return { ...state, companies: action.companies, status: action.status }
    case LOAD_POSITIONS:
      return { ...state, positions: action.positions, status: action.status }
    case CLEAR_STAFF_GROUP_CONTEXT:
      return { ...state, company_filter: '', position_filter: '', startDate: null, endDate: null,
        fired_filter: null, status: null, data: null, chart_data: null, interval: null
      }
    case CLEAR_STAFF_GROUP_CHART:
      return { ...state, chart_data: null}
    case SEARCHING_STAFF_GROUP_DATA: {
      return {
        ...state, searchStaffGroupInfo: true, searchStaffGroupWorkChartData: true
      }
    }
    case LOGOUT:
      return { ...state, company_filter: '', position_filter: '', startDate: null, endDate: null,
        fired_filter: null, status: null, companies: null, positions: null, data: null, chart_data: null,
        interval: null
      }
    default: return state
  }
}

